<table
  *ngIf="historyEvents.length > 0"
  mat-table
  [dataSource]="historyEvents"
  multiTemplateDataRows
  class="mat-elevation-z0">
  <ng-container matColumnDef="timestamp">
    <th
      mat-header-cell
      *matHeaderCellDef
      class="history-element-timestamp"
    >{{ 'booking.default.summary.history-events.titles.timestamp' | translate }}</th>
    <td
      mat-cell
      *matCellDef="let element"
      class="history-element-timestamp"
    >
      <time [dateTime]="element.timestamp">
        {{ element.timestamp | localizedDate: 'mediumDate' }},
        <br />
        {{ element.timestamp | localizedDate: 'mediumTime' }}&nbsp;Uhr
      </time>
    </td>
  </ng-container>
  <ng-container matColumnDef="historyKey">
    <th mat-header-cell
        *matHeaderCellDef> {{ 'booking.default.summary.history-events.titles.historyKey' | translate }} </th>
    <td mat-cell
        *matCellDef="let element"> {{ 'booking.default.summary.history-events.event-type.' + element.historyKey | translate }} </td>
  </ng-container>

  <ng-container matColumnDef="expand">
    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
    <td
      mat-cell
      *matCellDef="let element"
      class="history-element-expand"
    >
      <button mat-icon-button aria-label="expand row"
              (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
        <mat-icon
          *ngIf="expandedElement !== element"
          [attr.aria-label]="'booking.default.summary.history-events.controls.expand-event' | translate"
        >keyboard_arrow_down</mat-icon>
        <mat-icon
          *ngIf="expandedElement === element"
          [attr.aria-label]="'booking.default.summary.history-events.controls.fold-event' | translate"
        >keyboard_arrow_up</mat-icon>
      </button>
    </td>
  </ng-container>

  <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let element" [attr.colspan]="columnsToDisplay.length">
      <div class="history-element-detail"
           [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
        <dl>
          <dt>{{ 'booking.default.summary.history-events.details.timestamp' | translate }}</dt>
          <dd><time [dateTime]="element.timestamp">
            {{ element.timestamp | localizedDate: 'full' }}
          </time></dd>

          <dt
            *ngIf="element.priorPaymentStatus"
          >{{ 'booking.default.summary.history-events.details.prior-payment-status' | translate }}</dt>
          <dd
            *ngIf="element.priorPaymentStatus"
          >{{ 'booking.default.status.' + element.priorPaymentStatus.toLowerCase() | translate }}</dd>

          <dt
            *ngIf="element.newPaymentStatus"
          >{{ 'booking.default.summary.history-events.details.new-payment-status' | translate }}</dt>
          <dd
            *ngIf="element.newPaymentStatus"
          >{{ 'booking.default.status.' + element.newPaymentStatus.toLowerCase() | translate }}</dd>

          <dt
            *ngIf="element.selectedPaymentMethod"
          >{{ 'booking.default.summary.history-events.details.selected-payment-method.label' | translate }}</dt>
          <dd
            *ngIf="element.selectedPaymentMethod"
          >{{ 'booking.default.summary.history-events.details.selected-payment-method.' + element.selectedPaymentMethod.toLowerCase() | translate }}</dd>

          <dt
            *ngIf="element.refundedAmount"
          >{{ 'booking.default.summary.history-events.details.refunded-amount' | translate }}</dt>
          <dd
            *ngIf="element.refundedAmount"
          >{{
            element.refundedAmount.amount
                | currency
                : element.refundedAmount.currency
                  : undefined
                  : undefined
                  : currentLanguage
              }}
          </dd>

          <dt
            *ngIf="element.comment"
          >{{ 'booking.default.summary.history-events.details.comment' | translate }}</dt>
          <dd
            *ngIf="element.comment"
            class="comment-display"
          >{{ element.comment }}</dd>

          <dt
            *ngIf="element.fileName"
          >{{ 'booking.default.summary.history-events.details.filename' | translate }}</dt>
          <dd
            *ngIf="element.fileName"
          >{{ element.fileName }}</dd>

          <dt
            *ngIf="element.oldEvent"
          >{{ 'booking.default.summary.history-events.details.old-event' | translate }}</dt>
          <dd
            *ngIf="element.oldEvent"
          >
            <ng-container *ngIf="!element.oldEvent?.deleted">
              {{ 'booking.default.summary.history-events.details.event-format.full' | translate: {
                id: element.oldEvent?.id,
                date: element.oldEvent?.time | localizedDate: 'mediumDate',
                time: element.oldEvent?.time | localizedDate: 'shortTime',
                testcenterName: element.oldEvent?.testcenterName,
                testarea: element.oldEvent?.testarea
            } }}
            </ng-container>
            <ng-container *ngIf="element.oldEvent?.deleted">
              {{ 'booking.default.summary.history-events.details.event-format.minimal' | translate: {
                id: element.oldEvent?.id
            } }}
            </ng-container>
          </dd>

          <dt
            *ngIf="element.newEvent"
          >{{ 'booking.default.summary.history-events.details.new-event' | translate }}</dt>
          <dd
            *ngIf="element.newEvent"
          >
            <ng-container *ngIf="!element.newEvent?.deleted">
              {{ 'booking.default.summary.history-events.details.event-format.full' | translate: {
                id: element.newEvent?.id,
                date: element.newEvent?.time | localizedDate: 'mediumDate',
                time: element.newEvent?.time | localizedDate: 'shortTime',
                testcenterName: element.newEvent?.testcenterName,
                testarea: element.newEvent?.testarea
            } }}
            </ng-container>
            <ng-container *ngIf="element.newEvent?.deleted">
              {{ 'booking.default.summary.history-events.details.event-format.minimal' | translate: {
                id: element.newEvent?.id
              } }}
            </ng-container>
          </dd>

          <dt
            *ngIf="element.modifyingUser"
          >{{ 'booking.default.summary.history-events.details.modifying-user' | translate }}</dt>
          <dd
            *ngIf="element.modifyingUser"
          >
            <ng-container *ngIf="!element.modifyingUser?.deleted">
              {{ 'booking.default.summary.history-events.details.user-format.full' | translate: {
                id: element.modifyingUser?.id,
                firstname: element.modifyingUser?.firstname,
                lastname: element.modifyingUser?.lastname,
                username: element.modifyingUser?.username
            } }}
            </ng-container>
            <ng-container *ngIf="element.modifyingUser?.deleted">
              {{ 'booking.default.summary.history-events.details.user-format.minimal' | translate: {
                id: element.modifyingUser?.id
            } }}
            </ng-container>
          </dd>
        </dl>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
  <tr mat-row *matRowDef="let element; columns: columnsToDisplay;"
      class="history-element-row"
      [class.history-expanded-row]="expandedElement === element"
      (click)="expandedElement = expandedElement === element ? null : element">
  </tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="history-detail-row"></tr>
</table>
