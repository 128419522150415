import { AdditionalQuestionData } from './AdditionalQuestionData';
import { PrerequisiteQuestionDataResponse } from './response';

export type PaymentStatus =
  | 'open'
  | 'canceled'
  | 'pending'
  | 'authorized'
  | 'expired'
  | 'failed'
  | 'paid'
  | 'not_required'
  | 'order_canceled';

export interface EventBooking {
  additionalQuestionData?: AdditionalQuestionData;
  bookingId: number;
  bookingReference: string;
  deleted: boolean;
  paymentExpectedUntil?: string;
  prerequisiteQuestionId?: number;
  prerequisiteQuestionData?: PrerequisiteQuestionDataResponse;
  priceCalculationData?: PriceCalculationData;
  priceInCt?: number;
  reservedUntil: string;
  requestedPayment?: {
    amount: number;
    currency: 'EUR';
  };
  status: PaymentStatus;
  test: {
    id: number;
    logoPath?: string;
    name: string;
    templateName:
      | 'PhaST'
      | 'ITB-Business'
      | 'ITB-Science'
      | 'ITB-Technology'
      | 'TM-WISO'
      | 'GSAT'
      | 'SdV';
  };
  testEventId: number;
  userId: number;
}

export interface PriceCalculationData {
  dataProcessingForProctoringAccepted: boolean;
  parentage: ParentalData[];
  receivesBafoeg: ReceivesBafoeg;
  bafoegNumber?: string;
}

export interface ParentalData {
  type: ParentalRole;
  levelOfEducation: LevelOfEducation;
}

export enum ParentalRole {
  MOTHER = 'mother',
  FATHER = 'father',
  UNDEFINED = 'undefined',
}

export enum LevelOfEducation {
  COLLEGE = 'college-degree',
  MEISTER = 'german-meister',
  APPRENTICESHIP = 'apprenticeship-or-craftsmans-degree',
  VOCATIONAL_SCHOOL = 'degree-from-vocational-school',
  ENGINEER = 'state-certified-engineer',
  NO_PROFESSIONAL_TRAINING = 'no-professional-training',
  NOT_PROVIDED = 'not-provided',
  PARENT_UNKNOWN = 'parent-unknown',
  /**
   * @deprecated Was divided into 'NOT_PROVIDED' and 'PARENT_UNKNOWN'
   */
  UNKNOWN = 'unknown',
  UNDEFINED = 'undefined',
}

export enum ReceivesBafoeg {
  YES = 'receives-bafoeg.yes',
  NO = 'receives-bafoeg.no',
  UNKNOWN = 'receives-bafoeg.not-decided',
}
