<h2 mat-dialog-title>
  {{
    'title.payment-status-change' | translate : {
      bookingReference: data.bookingReference
    }
  }}
</h2>
<mat-dialog-content class="table-page-component">
  <mat-stepper [linear]="true" labelPosition="bottom">
    <mat-step>
      <form>
        <p>
          {{ 'change-payment-status.current-status-label' | translate }}
          <strong>{{ 'booking.' + tenant + '.status.' + data.currentPaymentStatus | lowercase | translate }}</strong>
        </p>
        <app-form-field-select
          [field]="targetPaymentStatusControl"
          [options]="targetPaymentStatusOptions"
          label="change-payment-status.target-status-label"
        ></app-form-field-select>

        <mat-dialog-actions>
          <button
            mat-stroked-button
            mat-dialog-close
            color="primary"
          >
            {{ 'button.cancel' | translate }}
          </button>
          <button
            mat-raised-button
            matStepperNext
            color="primary"
            [disabled]="!targetPaymentStatusControl.value"
          >
            {{ 'button.next' | translate }}
          </button>
        </mat-dialog-actions>
      </form>
    </mat-step>
    <mat-step>
      <div *ngIf="targetPaymentStatusControl.value === 'open'">
        <p>
          {{ 'change-payment-status.payment-expected-until' | translate }}
        </p>
        <app-form-field-datetime
          [field]="paymentExpectedByControl"
          [min]="earliestPossiblePayment"
          [max]="latestPossiblePayment"
          label="change-payment-status.payment-expected-until-label"
        ></app-form-field-datetime>
      </div>

      <div>
        <p>
          {{ 'change-payment-status.comment-label' | translate }}
        </p>
        <app-form-field-textarea
          [field]="paymentStatusChangeCommentControl"
          class="status-change-comment"
        ></app-form-field-textarea>
      </div>

      <mat-dialog-actions>
        <button mat-stroked-button matStepperPrevious>
          {{ 'button.back' | translate }}
        </button>
        <button
          mat-raised-button
          color="warn"
          (click)="changePaymentStatus()"
        >
          {{ 'button.payment-status-change' | translate }}
        </button>
      </mat-dialog-actions>
    </mat-step>
  </mat-stepper>
</mat-dialog-content>
