import { LayoutModule } from '@angular/cdk/layout';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { NgModule } from '@angular/core';
import { MatGridListModule } from '@angular/material/grid-list';
import { KeycloakAngularModule } from 'keycloak-angular';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { NgxFileDropModule } from 'ngx-file-drop';
import { LoggerModule } from 'ngx-logger';
import { environment } from 'src/environments/environment';
import { AppComponent } from './app.component';
import {
  CancelBookingDialogComponent,
  CandidateFormComponent,
  ChangeBookingDialogComponent,
  ChangePaymentStatusDialogComponent,
  ConfirmationDialogComponent,
  DashboardBookingCardComponent,
  DashboardExamCardComponent,
  EditEmailDialogComponent,
  ExamBookingHistoryTableComponent,
  ExamCenterFormComponent,
  ExamCenterTableComponent,
  ExamCenterTableFilterComponent,
  ExamEventDetailsDialogComponent,
  ExamEventFormComponent,
  ExamEventStatisticsTableComponent,
  ExamEventTableComponent,
  ExamFormComponent,
  ExamLocationFormComponent,
  ExamLocationTableComponent,
  ExamLocationTableFilterComponent,
  ExamTableComponent,
  ExamTableFilterComponent,
  FooterComponent,
  ForgotPasswordDialogComponent,
  ForgotPasswordSuccessDialogComponent,
  HeaderNavbarComponent,
  I18nButtonComponent,
  InfoPageDialogComponent,
  InviteDialogComponent,
  InviteErrorDialogComponent,
  InviteSuccessDialogComponent,
  LoadingDialogComponent,
  MbrUserRegistrationTimeoutComponent,
  ProfileButtonComponent,
  ProfileCardComponent,
  ProfileEditFormComponent,
  RegisterForExamFormComponent,
  RegisterFormComponent,
  ResetPasswordFormComponent,
  UserFormComponent,
  UserTableComponent,
  UserTableFilterComponent,
} from './components';
import { ExamBookingFormModule } from './components/forms/exam-booking/exam-booking-form.module';
import { AppRoutingModule } from './config/app-routing.module';
import {
  BASE_API_INTERCEPTOR_PROVIDER,
  BASE_API_URL_PROVIDER,
} from './config/base-url.interceptor';
import { KEYCLOAK_PROVIDER } from './config/keycloak';
import { NGX_TRANSLATE_CONFIG } from './config/ngx-translate-config';
import { SharedModule } from './config/shared.module';
import {
  DefaultLayoutComponent,
  MbrRegistrationPageComponent,
  PlainLayoutComponent,
} from './layouts';
import { NotificationModule } from './lib/notification/notification.module';
import { PaginatorComponent } from './lib/table';
import {
  CandidateFormPageComponent,
  ContactPageComponent,
  DashboardPageComponent,
  Error404PageComponent,
  ExamBookingFormPageComponent,
  ExamBookingSummaryPageComponent,
  ExamCenterFormPageComponent,
  ExamCenterPageComponent,
  ExamFormPageComponent,
  ExamLocationFormPageComponent,
  ExamLocationPageComponent,
  ExamPageComponent,
  ImprintPageComponent,
  LandingPageComponent,
  MbrRetrieveNamesPageComponent,
  MyBookingsPageComponent,
  MyRegistrationsPageComponent,
  PrivacyTermsPageComponent,
  ProfileEditPageComponent,
  ProfilePageComponent,
  RegisterPageComponent,
  ResetPasswordFormPageComponent,
  SystemRequirementsPageComponent,
  TermsOfServicePageComponent,
  UserFormPageComponent,
  UserPageComponent,
} from './pages';
import { HasRequiredValidatorPipe } from './pipes';

registerLocaleData(localeEn, 'en');
registerLocaleData(localeDe, 'de');

@NgModule({
  declarations: [
    AppComponent,
    CancelBookingDialogComponent,
    CandidateFormComponent,
    CandidateFormPageComponent,
    ChangeBookingDialogComponent,
    ChangePaymentStatusDialogComponent,
    ConfirmationDialogComponent,
    ContactPageComponent,
    DashboardBookingCardComponent,
    DashboardExamCardComponent,
    DashboardPageComponent,
    DefaultLayoutComponent,
    EditEmailDialogComponent,
    Error404PageComponent,
    ExamBookingFormPageComponent,
    ExamBookingHistoryTableComponent,
    ExamBookingSummaryPageComponent,
    ExamCenterFormComponent,
    ExamCenterFormPageComponent,
    ExamCenterPageComponent,
    ExamCenterTableComponent,
    ExamCenterTableFilterComponent,
    ExamEventDetailsDialogComponent,
    ExamEventFormComponent,
    ExamEventStatisticsTableComponent,
    ExamEventTableComponent,
    ExamFormComponent,
    ExamFormPageComponent,
    ExamLocationFormComponent,
    ExamLocationFormPageComponent,
    ExamLocationPageComponent,
    ExamLocationTableComponent,
    ExamLocationTableFilterComponent,
    ExamPageComponent,
    ExamTableComponent,
    ExamTableFilterComponent,
    FooterComponent,
    ForgotPasswordDialogComponent,
    ForgotPasswordSuccessDialogComponent,
    HeaderNavbarComponent,
    I18nButtonComponent,
    ImprintPageComponent,
    InfoPageDialogComponent,
    InviteDialogComponent,
    InviteErrorDialogComponent,
    InviteSuccessDialogComponent,
    LandingPageComponent,
    LoadingDialogComponent,
    MbrRegistrationPageComponent,
    MbrRetrieveNamesPageComponent,
    MbrUserRegistrationTimeoutComponent,
    MyBookingsPageComponent,
    MyRegistrationsPageComponent,
    PaginatorComponent,
    PlainLayoutComponent,
    PrivacyTermsPageComponent,
    ProfileButtonComponent,
    ProfileCardComponent,
    ProfileEditFormComponent,
    ProfileEditPageComponent,
    ProfilePageComponent,
    RegisterForExamFormComponent,
    RegisterFormComponent,
    RegisterPageComponent,
    ResetPasswordFormComponent,
    ResetPasswordFormPageComponent,
    SystemRequirementsPageComponent,
    TermsOfServicePageComponent,
    UserFormComponent,
    UserFormPageComponent,
    UserPageComponent,
    UserTableComponent,
    UserTableFilterComponent,
  ],
  imports: [
    AppRoutingModule,
    CollapseModule,
    ExamBookingFormModule,
    KeycloakAngularModule,
    LayoutModule,
    LoggerModule.forRoot({
      level: environment.logLevel,
      disableConsoleLogging: false,
    }),
    MatGridListModule,
    NGX_TRANSLATE_CONFIG,
    NgxFileDropModule,
    NotificationModule,
    SharedModule,
    HasRequiredValidatorPipe,
  ],
  providers: [
    KEYCLOAK_PROVIDER,
    BASE_API_URL_PROVIDER,
    BASE_API_INTERCEPTOR_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
