import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { SelectOption } from '../../../types';
import { FormFieldAbstractComponent } from '../form-field-abstract.component';

@Component({
  selector: 'app-form-field-datetime',
  templateUrl: './form-field-datetime.component.html',
  styleUrls: ['./form-field-datetime.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class FormFieldDatetimeComponent
  extends FormFieldAbstractComponent
  implements OnInit
{
  @ViewChild(MatMenuTrigger, { static: false })
  trigger!: MatMenuTrigger;
  @Input() min: Date | null = null;
  @Input() max: Date | null = null;
  selectedDate: Date | null = null;
  hours: FormControl<string | null> = new FormControl<
    string | null
  >('00');
  hoursOptions: SelectOption[] = [];
  minutes: FormControl<string | null> = new FormControl<
    string | null
  >('00');
  minutesOptions: SelectOption[] = [];
  seconds: FormControl<string | null> = new FormControl<
    string | null
  >('00');
  secondsOptions: SelectOption[] = [];

  get hasValue(): boolean {
    return String(this.field?.value || '').length > 0;
  }

  handleDateChanged(date: Date | null): void {
    this.selectedDate = date;
  }

  clear(): void {
    this.selectedDate = null;
    this.field.setValue(null);
  }

  preventMenuFromClosingDuringSelection(event: any) {
    event.stopPropagation();
  }

  select(): void {
    if (!this.selectedDate) {
      this.selectedDate = new Date();
    }
    const date = this.selectedDate;
    date.setHours(Number(this.hours.value));
    date.setMinutes(Number(this.minutes.value));
    date.setSeconds(Number(this.seconds.value));
    this.field.setValue(date.toISOString());
    this.trigger.closeMenu();
  }

  ngOnInit(): void {
    this.seconds = new FormControl<string | null>('00');
    this.secondsOptions = this.createSelectOptions(60);
    this.minutes = new FormControl<string | null>('00');
    this.minutesOptions = this.createSelectOptions(60);
    this.hours = new FormControl<string | null>('00');
    this.hoursOptions = this.createSelectOptions(24);
  }

  private createSelectOptions(
    count: number
  ): SelectOption[] {
    const options: SelectOption[] = [];
    for (let i = 0; i <= count; i++) {
      const stringValue = i > 9 ? `${i}` : `0${i}`;
      options.push({
        value: stringValue,
        label: stringValue,
      } as SelectOption);
    }
    return options;
  }
}
