import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { DateTime } from 'luxon';
import { environment } from '../../../../environments/environment';
import { TenantId } from '../../../../environments/environments.types';
import { BookingService } from '../../../services';
import {
  PaymentStatus,
  SelectOption,
} from '../../../types';

@Component({
  selector: 'app-change-payment-status-dialog',
  templateUrl:
    './change-payment-status-dialog.component.html',
  styleUrls: [
    './change-payment-status-dialog.component.scss',
  ],
})
export class ChangePaymentStatusDialogComponent
  implements OnInit
{
  targetPaymentStatusOptions!: SelectOption[];

  targetPaymentStatusControl = new FormControl<
    PaymentStatus | undefined
  >(undefined, Validators.required);
  paymentExpectedByControl = new FormControl<
    string | undefined
  >(undefined);
  paymentStatusChangeCommentControl =
    new FormControl<string>('');

  private incompleteStatuses: PaymentStatus[] = [
    'open',
    'canceled',
    'pending',
    'authorized',
    'expired',
    'failed',
  ];

  constructor(
    private dialog: MatDialogRef<ChangePaymentStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      bookingId: number;
      bookingReference: string;
      currentPaymentStatus: PaymentStatus;
    },
    private bookingService: BookingService
  ) {}

  ngOnInit(): void {
    if (
      this.incompleteStatuses.includes(
        this.data.currentPaymentStatus
      )
    ) {
      this.targetPaymentStatusOptions =
        this.paymentStatusesToSelectOptions([
          'open',
          'paid',
          'not_required',
        ]);
    } else {
      // This shouldn't happen
      this.targetPaymentStatusOptions = [];
      console.error(
        `The booking ${this.data.bookingId} is in status ${this.data.currentPaymentStatus}, that cannot be changed.`
      );
    }
  }

  private paymentStatusesToSelectOptions = (
    statuses: PaymentStatus[]
  ): SelectOption[] => {
    return statuses.map((status) => ({
      value: status,
      label: `booking.${this.tenant}.status.${status}`,
    }));
  };

  async changePaymentStatus(): Promise<void> {
    const targetStatus =
      this.targetPaymentStatusControl.value;
    if (targetStatus) {
      const comment =
        this.paymentStatusChangeCommentControl.value || '';
      // We only want to send a "payment expected by" date when the target status is 'open'
      const paymentExpectedBy =
        targetStatus === 'open' &&
        this.paymentExpectedByControl.value
          ? DateTime.fromISO(
              this.paymentExpectedByControl.value
            )
          : undefined;
      await this.bookingService.changeBookingPaymentStatus(
        this.data.bookingId,
        targetStatus,
        comment,
        paymentExpectedBy
      );
      this.dialog.close();
    } else {
      console.error(
        `Could not request changing the payment status of booking ${this.data.bookingId}. No target payment status selected`
      );
      return Promise.reject(
        new Error('No target payment status selected')
      );
    }
  }

  get tenant(): TenantId {
    return environment.tenant;
  }

  get earliestPossiblePayment(): Date {
    return DateTime.now()
      .setZone('Europe/Berlin')
      .plus({
        minute: 30,
      })
      .toJSDate();
  }

  get latestPossiblePayment(): Date {
    return DateTime.now()
      .setZone('Europe/Berlin')
      .plus({
        week: 4,
      })
      .toJSDate();
  }
}
