import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { Profile, User } from '../../types';
import { UserService } from '../user';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private userPromise$?: Promise<User>;

  profile: Profile = {
    user: UserService.factoryItem(),
  };

  constructor(private http: HttpClient) {}

  get isAdmin(): boolean {
    return this.profile.user.admin;
  }

  load(): Promise<User> {
    if (!this.userPromise$) {
      this.userPromise$ = firstValueFrom(
        this.http.get<User>('/api/profile')
      ).then((response) => (this.profile.user = response));
    }

    return this.userPromise$;
  }
}
