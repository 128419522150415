import { HttpResponse } from '@angular/common/http';
import {
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import {
  FileSystemDirectoryEntry,
  FileSystemEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';
import { NGXLogger } from 'ngx-logger';
import prettyBytes from 'pretty-bytes';
import { Observable, Subscription, timer } from 'rxjs';
import {
  CancelBookingDialogComponent,
  ChangeBookingDialogComponent,
  ConfirmationDialogComponent,
} from 'src/app/components';
import Tools from 'src/app/lib/Tools';
import {
  BookingService,
  ExamEventService,
  NotificationService,
  ProfileService,
} from 'src/app/services';
import {
  AdditionalBookingQuestionsResponse,
  EventBooking,
  EventBookingFileInfo,
  ExamEvent,
  InfotextResponse,
  LineBreakBlock,
  LinkResponseBlock,
  ResponseBlock,
  TextFormattingType,
  TextResponseBlock,
} from 'src/app/types';
import { ExamBookingAddQuestionsUserAnswers } from 'src/app/types/ExamBookingAddQuestionsUserAnswers';
import { environment } from 'src/environments/environment';
import { BreadcrumbService } from 'xng-breadcrumb';
import { TenantId } from '../../../environments/environments.types';
import { ChangePaymentStatusDialogComponent } from '../../components';

@Component({
  selector: 'app-exam-booking-summary-page',
  templateUrl: './exam-booking-summary-page.component.html',
  styleUrls: ['./exam-booking-summary-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ExamBookingSummaryPageComponent
  implements OnInit, OnDestroy
{
  booking!: EventBooking;
  bookingFiles!: EventBookingFileInfo[];
  bookingLoadedCounter = 0;
  examEvent!: ExamEvent;
  isHoveringOverUpload!: boolean;
  isLoading!: boolean;
  userAnswers: ExamBookingAddQuestionsUserAnswers = {
    language: '',
    universityIds: [],
    sendResultsToUniversityIds: [],
    finalGrade: '',
  };

  private addQuestions!: AdditionalBookingQuestionsResponse;
  private bookingId!: number;
  private currentlyUploading: { [key: string]: boolean } =
    {};
  private everyTenSeconds: Observable<number> = timer(
    0,
    10000
  );
  private infotext?: InfotextResponse;
  private unsubOnDestroy = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private bookingService: BookingService,
    private breadcrumbService: BreadcrumbService,
    private dialog: MatDialog,
    private examEventService: ExamEventService,
    private logger: NGXLogger,
    private notificationService: NotificationService,
    private profileService: ProfileService,
    private translateService: TranslateService
  ) {
    this.breadcrumbService.set('@bookingName', {
      skip: true,
    });
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    const bookingIdParam =
      this.activatedRoute.snapshot.paramMap.get('id');

    this.profileService.load();

    if (bookingIdParam) {
      this.bookingId = Number(
        bookingIdParam.match(/^\d+/i)
      );
      try {
        await this.getBooking();

        this.unsubOnDestroy.add(
          this.getTranslation('title.booking').subscribe(
            (translation) => {
              this.breadcrumbService.set('@bookingName', {
                label: `${translation} #${this.booking.bookingReference}`,
                skip: false,
              });
            }
          )
        );

        await this.getExamEvent(this.booking.testEventId);

        this.addQuestions =
          await this.bookingService.loadAdditionalBookingQuestions(
            this.booking.bookingId,
            { loadDeletedBookings: true }
          );
        this.getUserAnswers(this.booking);
        this.isLoading = false;

        this.unsubOnDestroy.add(
          this.everyTenSeconds.subscribe(() => {
            this.getBooking();
          })
        );
      } catch (e: unknown) {
        this.fail(e);
      }

      await this.getBookingFiles();
    }
  }

  get tenant(): TenantId {
    return environment.tenant;
  }

  get currentLanguage(): string {
    return this.translateService.currentLang;
  }

  get timeSlot(): Date | undefined {
    if (this.examEvent) {
      return Tools.dateFromIso(
        this.examEvent.testeventTime
      );
    }
    return;
  }

  get timeSlotDate(): string | undefined {
    if (this.examEvent) {
      return DateTime.fromISO(
        this.examEvent.testeventTime
      ).toISODate();
    }
    return;
  }

  get timeSlotTime(): string | undefined {
    if (this.examEvent) {
      return DateTime.fromISO(
        this.examEvent.testeventTime
      ).toISOTime();
    }
    return;
  }

  get isAdmin(): boolean {
    return this.profileService.isAdmin;
  }

  get paymentCouldArriveShortly(): boolean {
    if (this.booking.status !== 'open') {
      return false;
    }
    if (!this.booking.deleted) {
      return false;
    }
    return this.bookingLoadedCounter <= 2;
  }

  get canBePaidFor(): boolean {
    if (this.booking.deleted) {
      return false;
    }
    if (this.paymentCouldArriveShortly) {
      return false;
    }
    return ['open', 'failed', 'canceled'].includes(
      this.booking.status
    );
  }

  /**
   * Retrieve the automatic cancelation timestamp.
   * If no payment has been started, there will be no "paymentExpectedUntil" field.
   */
  get automaticCancelDate(): string | undefined {
    const { reservedUntil, paymentExpectedUntil } =
      this.booking;
    if (!paymentExpectedUntil) {
      return reservedUntil;
    } else {
      return reservedUntil < paymentExpectedUntil
        ? reservedUntil
        : paymentExpectedUntil;
    }
  }

  get paymentIsComplete(): boolean {
    if (!this.booking) {
      return false;
    }
    return ['paid', 'not_required'].includes(
      this.booking.status
    );
  }

  get bookingHasInfotext(): boolean {
    if (!this.booking) {
      return false;
    }
    return [
      'PhaST',
      'TM-WISO',
      'ITB-Business',
      'SdV',
    ].includes(this.booking.test.templateName);
  }

  private getFormattingFromBlock(
    block: ResponseBlock
  ): TextFormattingType[] {
    switch (block.type) {
      case 'HEADING': {
        return [];
      }
      case 'TEXT': {
        return block.formatting || [];
      }
      case 'LINK': {
        return block.formatting || [];
      }
      case 'LINE_BREAK': {
        return [];
      }
    }
  }

  isBoldInfotext(block: ResponseBlock): boolean {
    return this.getFormattingFromBlock(block).includes(
      'BOLD'
    );
  }

  isItalicInfotext(block: ResponseBlock): boolean {
    return this.getFormattingFromBlock(block).includes(
      'ITALIC'
    );
  }

  isUnderlinedInfotext(block: ResponseBlock): boolean {
    return this.getFormattingFromBlock(block).includes(
      'UNDERLINED'
    );
  }

  getLinkFromLinkBlock(block: ResponseBlock): string {
    return (block as LinkResponseBlock).link;
  }

  doPayment(): void {
    this.bookingService.doPayment(this.bookingId);
  }

  changePaymentStatusDialog(): void {
    const dialogRef = this.dialog.open(
      ChangePaymentStatusDialogComponent,
      {
        data: {
          bookingId: this.booking.bookingId,
          bookingReference: this.booking.bookingReference,
          currentPaymentStatus: this.booking.status,
        },
        minWidth: '80vw',
        panelClass: 'position-relative',
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      this.getBooking();
    });
  }

  changeBookingDialog(): void {
    const dialogRef = this.dialog.open(
      ChangeBookingDialogComponent,
      {
        data: this.booking.bookingId,
        minWidth: '80vw',
        minHeight: '50vh',
        panelClass: 'position-relative',
      }
    );

    dialogRef.afterClosed().subscribe(() => {
      this.getBooking();
    });
  }

  cancelBookingDialog(): void {
    this.dialog.open(CancelBookingDialogComponent, {
      data: this.booking.bookingId,
      minWidth: '80vw',
      minHeight: '50vh',
      panelClass: 'position-relative',
    });
  }

  ngOnDestroy(): void {
    this.unsubOnDestroy.unsubscribe();
  }

  private getTranslation(key: string): Observable<string> {
    return this.translateService.stream(key);
  }

  private async getExamEvent(
    testEventId: number
  ): Promise<void> {
    this.examEvent =
      await this.examEventService.loadByEventId(
        testEventId
      );
  }

  private async getBooking(): Promise<void> {
    this.booking =
      await this.bookingService.loadBookingById(
        this.bookingId,
        {
          loadDeletedBookings: true,
        }
      );
    this.getExamEvent(this.booking.testEventId);
    this.bookingLoadedCounter++;
    if (this.paymentIsComplete && !this.infotext) {
      try {
        this.infotext =
          await this.bookingService.getInfotextForCompletedBooking(
            this.bookingId
          );
      } catch (e) {
        console.log(
          `No infotext for completed booking ${this.bookingId}.`
        );
        this.infotext = {};
      }
    }
  }

  private getUserAnswers(booking: EventBooking): void {
    this.userAnswers.universityIds =
      this.addQuestions.universities?.filter((uni) =>
        booking.additionalQuestionData?.universityIds?.includes(
          uni.id
        )
      );

    switch (booking.test.templateName) {
      case 'TM-WISO': {
        this.userAnswers.language =
          booking.additionalQuestionData?.language ?? '';

        this.userAnswers.sendResultsToUniversityIds =
          this.addQuestions.sendResultsToUniversities?.filter(
            (uni) =>
              booking.additionalQuestionData?.sendResultsToUniversityIds?.includes(
                uni.id
              )
          );
        break;
      }
      case 'PhaST': {
        this.userAnswers.finalGrade =
          booking.additionalQuestionData?.finalGrade;
        break;
      }
      case 'GSAT': {
        this.userAnswers.language =
          booking.additionalQuestionData?.language ?? '';
        break;
      }
      case 'ITB-Business': {
        this.userAnswers.language =
          booking.additionalQuestionData?.language ?? '';
        this.userAnswers.sendResultsToUniversityIds =
          this.addQuestions.sendResultsToUniversities?.filter(
            (uni) =>
              booking.additionalQuestionData?.sendResultsToUniversityIds?.includes(
                uni.id
              )
          );
        this.userAnswers.finalGrade =
          booking.additionalQuestionData?.finalGrade;
        break;
      }
      case 'ITB-Science':
      case 'ITB-Technology': {
        this.userAnswers.language =
          booking.additionalQuestionData?.language ?? '';
        this.userAnswers.finalGrade =
          booking.additionalQuestionData?.finalGrade;
        break;
      }
    }
  }

  private async getBookingFiles(): Promise<void> {
    const response =
      await this.bookingService.loadBookingFilesByBookingId(
        this.bookingId
      );
    this.bookingFiles = response.items;
    this.sortBookings();
  }

  private sortBookings(): void {
    this.bookingFiles.sort((a, b) =>
      (a.uploadedAt || '') < (b.uploadedAt || '') ? 1 : -1
    );
  }

  fileSize(fileInfo: EventBookingFileInfo): string {
    return prettyBytes(fileInfo.fileSizeInBytes, {
      locale: this.currentLanguage.substr(0, 2),
    });
  }

  async downloadFile(
    internalFileId: string,
    defaultFileName: string
  ): Promise<void> {
    try {
      const response =
        await this.bookingService.getBookingFileByFileKey(
          this.bookingId,
          internalFileId
        );

      if (!response.body) {
        throw new Error(
          `No body present in file download for file key ${internalFileId} in booking ${this.bookingId}.`
        );
      }

      const fileName =
        this.fileNameFromContentDispositionHeader(
          response
        ) || defaultFileName;

      saveAs(response.body, fileName);
    } catch (error) {
      this.logger.error(error);
      await this.notificationService.error(
        'error.file-download-failed'
      );
    }
  }

  deleteFile(fileInfo: EventBookingFileInfo): void {
    const dialogRef = this.dialog.open(
      ConfirmationDialogComponent,
      {
        width: '400px',
        data: {
          title: 'file-download.confirm-deletion.title',
          content: 'file-download.confirm-deletion.content',
          data: {
            fileName: fileInfo.fileName,
          },
        },
      }
    );
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        try {
          const response =
            await this.bookingService.deleteFileForBooking(
              this.bookingId,
              fileInfo.internalFileId
            );

          if (response.status === 200) {
            // File deleted
            await this.getBookingFiles();
          }
        } catch (error) {
          this.logger.error(error);
          await this.notificationService.error(
            'error.file-deletion-failed'
          );
        }
      }
    });
  }

  private fileNameFromContentDispositionHeader(
    response: HttpResponse<any>
  ): string | undefined {
    const contentDispositionHeader: string =
      response.headers.get('content-disposition') || '';
    const matches = /filename="(?<filename>.*)"/.exec(
      contentDispositionHeader
    );

    return matches?.groups?.filename;
  }

  setHoveringOverUpload(hovering: boolean): void {
    this.isHoveringOverUpload = hovering;
  }

  async uploadFile(
    droppedFiles: NgxFileDropEntry[]
  ): Promise<void> {
    this.setHoveringOverUpload(false);

    await Promise.all(
      droppedFiles.map(({ fileEntry, relativePath }) =>
        this.uploadFileEntries(fileEntry, relativePath)
      )
    );
  }

  private async uploadFileEntries(
    fileSystemEntry: FileSystemEntry,
    relativePath: string
  ): Promise<void> {
    if (fileSystemEntry.isFile) {
      const fileEntry =
        fileSystemEntry as FileSystemFileEntry;

      await fileEntry.file(async (file: File) => {
        // Skip files with size 0 or files that are hidden in unixoid OSes.
        // We can't skip hidden files on Windows, since we don't seem to be able to identify those as hidden.
        if (file.size === 0 || file.name.startsWith('.')) {
          return;
        }

        // Mark that this file is currently being uploaded
        this.currentlyUploading[file.name] = true;

        const uploadedFileInfo =
          await this.bookingService.uploadFileForBooking(
            file,
            relativePath,
            this.bookingId
          );
        this.bookingFiles.push(uploadedFileInfo);
        this.sortBookings();

        // Mark that the upload of this file has been completed
        this.currentlyUploading[file.name] = false;
      });
    } else if (fileSystemEntry.isDirectory) {
      const dirEntry =
        fileSystemEntry as FileSystemDirectoryEntry;
      const dirReader = dirEntry.createReader();
      // For directories, we go through
      dirReader.readEntries((dirFiles: FileSystemEntry[]) =>
        dirFiles.forEach((dirFile) =>
          this.uploadFileEntries(
            dirFile,
            relativePath + '/' + dirEntry.name
          )
        )
      );
    } else {
      // I don't think we can actually trigger this case. In theory (at least on unixoid OSes), this would mean that the
      // file someone tried to upload is:
      // - a block file
      // - a character file
      // - a pipe file
      // - a symbolic link file
      // - a socket file
      // However, the upload mechanism doesn't seem to react to such files at all.
      this.notificationService.error(
        `Could not upload file ${fileSystemEntry.name} because it has an unexpected type (not a file or a directory).`,
        'Error during file upload'
      );
      this.logger.error(
        `Could not upload file ${fileSystemEntry.name} because it has an unexpected type (not a file and not a directory).`
      );
    }
  }

  get isUploading(): boolean {
    const keys = Object.keys(this.currentlyUploading);
    for (const key of keys) {
      if (this.currentlyUploading[key]) {
        return true;
      }
    }
    return false;
  }

  get isOptionalQuestionAnswered(): boolean | undefined {
    const addQuestions =
      this.booking?.additionalQuestionData;

    if (addQuestions) {
      const { finalGrade, studentDetails } = addQuestions;
      const existingAnswers = new Set([
        finalGrade,
        studentDetails?.schoolSubjects?.german?.grade,
        studentDetails?.schoolSubjects?.german?.points,
        studentDetails?.schoolSubjects?.mathematics?.grade,
        studentDetails?.schoolSubjects?.mathematics?.points,
        studentDetails?.germanLanguageLevel,
        studentDetails?.migrationBackground,
      ]);
      // Any of the answers may be undefined, so we'll remove that as it is really a non-answer.
      existingAnswers.delete(undefined);
      return existingAnswers.size > 0; // If at least one answer has been given, return true.
    } else {
      return;
    }
  }

  get infotextBlocks(): ResponseBlock[] {
    if (!this.infotext || !this.infotext.blocks) {
      return [];
    }
    return this.infotext.blocks.flatMap(
      (block: ResponseBlock) => {
        const lines = block.value.split('\n');
        const newBlocks = [];
        for (let i = 0; i < lines.length; i++) {
          newBlocks.push({
            ...block,
            value: lines[i].trim(),
          } as TextResponseBlock);
          // Add line breaks between lines, except for after the last one.
          if (i < lines.length - 1) {
            newBlocks.push({
              type: 'LINE_BREAK',
              value: '',
            } as LineBreakBlock);
          }
        }
        return newBlocks;
      }
    );
  }

  blockStartsWithDot(block: ResponseBlock): boolean {
    return block.value.trimStart().startsWith('.');
  }

  private fail(e?: unknown): void {
    this.logger.error('load booking details failed', e);
  }
}
